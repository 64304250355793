<template>
  <div class="active-rules">
    <div class="a-header">
      <div class="a-icon" @click="toHome">
        <span class="iconfont icon-left"></span>
      </div>
      <div class="a-title">活动规则</div>
    </div>
    <div class="a-main" v-html="rule_content">
      <!-- <p>活动规则</p>
            <p>1、在开启了红包活动的游戏区服内创建的角色即有资格参加红包活动;</p>
            <p>2、活动时间60天，首次创建角色后即开始倒计时，60天倒计时结束则活动过期，活动过期后等级红包及充值红包无法再领取;邀请红包可继续领取(邀请红包不受活动时间限制);</p>
            <p>3、活动过期后，普通提现红包余额将会清0;闪电提现红包余额不受影响，可正常提现;</p>
            <p>4、活动奖项条件中要求的创角时间在等级红包中为游戏中首个角色创建时间，在充值红包中为当前角色创建时间;</p>
            <p>5、每个红包奖项每个账号(无论创建了 多少个角色)只可领取1次;</p>
            <p>6、活动过程中，凡以不正当手段(包括但不限于:创角时间与开服时间差距太大、侵犯第三人合法权利、作弊、违规账号、恶意刷奖、虚假交易、扰乱系统、网络攻击等)参与活动的用户，将被永久取消活动资格;</p>
            <p>7、活动过程中有任何疑问，可进入活动页面最下方"客.服中心”咨询反馈;</p>
            <p>8、本活动解释权在法律允许范围内归本公司所有。</p> -->
    </div>
  </div>
</template>

<script>
import { getRedList } from '@/api/redList';

export default {
  name: 'ActivityRules',
  data() {
    return {
      moneyValue: [],
      currentIndex: 0,
      rule_content: '',
    };
  },
  activated() {
    console.log('参数', this.$route.query);
    this.rule_content = this.$route.query?.redData || '';
  },
  methods: {
    toHome() {
      // this.$router.back();
      this.$router.push('/home');
    },
    selectMoney(index) {
      this.currentIndex = index;
    },
    httpGetRedList() {
      const appid = window.sessionStorage.getItem('appid');
      const token = window.sessionStorage.getItem('ato');

      if (appid && token) {
        getRedList({
          app_id: window.sessionStorage.getItem('appid'),
          token: window.sessionStorage.getItem('ato'),
        }).then((res) => {
          console.log('redList', res.data);
          if (res.data.status === 200) {
            this.rule_content = res.data.data.rule_content;
          } else {
            this.$toast(res.data.msg);
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.active-rules {
  // background-color: #fff;
  .a-header {
    background-color: #f6f6f6;
    position: relative;
    height: 1rem;
    display: flex;
    text-align: center;
    align-items: center;
    .a-icon {
      position: absolute;
      width: 0.4rem;
      height: 0.4rem;
      .icon-left {
        font-size: 0.34rem;
        color: #333;
        margin-left: 0.3rem;
      }
    }
    .a-title {
      flex: 1;
      font-size: 0.32rem;
    }
  }
  .a-main {
    padding: 0 0.2rem;
    margin-top: 0.1rem;
  }
}
</style>
